<template lang="">
    <div class="p-5">
        <b-card :header="$t('HomePage.aboutus')">
            Coming soon........
        </b-card>
        
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>